import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo  } from 'gatsby-plugin-next-seo';
import get from 'lodash/get'
import DateIcon from "../../assets/svg/date.inline.svg"


class Page extends React.Component {
  render() { 
  const allWpPost = get(this, 'props.data.allWpPost')
  const page = get(this, 'props.data.page')

  return (
    <Layout bodyClass={`page-template-default page page-id-${page.databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.nettikasinolla.com/${page.slug}/`}
      />
      <article className={`post-${page.databaseId} post page type-page status-publish hentry testi`} id={`post-${page.atabaseId}`}>
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title"
              dangerouslySetInnerHTML={{ __html: page.title }}
            />
          </div>
        </header>
        <div className="post-inner thin container">
          <div className="entry-content"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </div>
        {allWpPost.edges.map(({node})=> (
        <article key={node.databaseId} className={`post-${node.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized archive-post`} id={`post-${node.databaseId}`}>
          <header className="entry-header has-text-align-center">
            <div className="entry-header-inner section-inner medium">
              {node.featuredImage !== null &&
                <img src={node.featuredImage.node.mediaItemUrl} className="featured"  alt={node.featuredImage.node.altText}/>
              }
              <h2 className="entry-title heading-size-2">
                <Link to={node.uri}
                  dangerouslySetInnerHTML={{ __html: node.title }}
                />
              </h2>
              <span className="meta-date"><DateIcon /> {node.date}</span>
            </div>
          </header>
          <div className="post-inner thin ">
            <div className="entry-content">
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          </div>
        </article>
        ))}
      </article>
    </Layout>
  )
}
}

export default Page

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
    allWpPost {
      edges {
        node {
          id
          title
          content
          databaseId
          date (formatString: "DD.MM.YYYY")
          excerpt
          seo {
            metaDesc
            title
          }
          uri
          featuredImage {
            node {
              id
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
